.ao-breadcrumb {
  height: 48px;
  font-weight: bold; }
  .ao-breadcrumb .breadcrumb {
    font-size: 0.9375rem; }
    .ao-breadcrumb .breadcrumb .breadcrumb-item {
      text-transform: uppercase;
      color: #f1f1f6; }
      .ao-breadcrumb .breadcrumb .breadcrumb-item + .breadcrumb-item::before {
        color: #f1f1f6; }
