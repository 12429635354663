.ao-view {
  width: 100%;
  background: transparent; }
  .ao-view .ao-view__content {
    height: 100%;
    overflow: auto; }
  .ao-view .ao-view__header {
    background: #FFFFFF;
    min-height: 128px;
    border-bottom: 1px solid #e0e0e0;
    padding-left: 1rem;
    padding-right: 1rem; }
  .ao-view .ao-view__header--tabs {
    border-bottom: transparent; }
