.ao-navigation {
  position: absolute;
  top: 0;
  left: 0;
  background: #001529;
  height: 100%;
  z-index: 999;
  justify-content: flex-start;
  overflow: hidden;
  width: 64px;
  transition: all cubic-bezier(0.445, 0.05, 0.55, 0.95) 0.3s;
  display: flex;
  flex-direction: column;
  align-items: flex-start; }
  .ao-navigation--expanded {
    overflow: visible;
    width: 240px; }
.ao-navigation-inner-not-visible {
    display: none !important;}
  .ao-navigation .navbar-toggler {
    padding: 0.5rem 1rem; }
  .ao-navigation .ao-navigation__item {
    height: 64px;
    width: 100%;
    padding-left: 8px; }
    .ao-navigation .ao-navigation__item .nav-link {
      display: grid;
      grid-template-columns: 64px 1fr;
      color: #f1f1f6;
      height: 100%;
      width: 100%;
      text-transform: uppercase;
      align-items: center; }
      .ao-navigation .ao-navigation__item .nav-link:hover {
        color: #1890ff; }
