.ao-app {
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: row; }
  .ao-app .ao-app__header-container {
    position: relative;
    display: flex;
    flex-direction: row;
    height: 64px;
    background-color: #001529;
    align-items: center;
    color: #f1f1f6; }
    .ao-app .ao-app__header-container .ao-app__brand {
      width: 360px;
      height: 100%;
      background-color: #1890ff;
      display: flex;
      flex-direction: column;
      justify-content: center;
      padding-left: 16px; }
      .ao-app .ao-app__header-container .ao-app__brand .ao-app__brandname {
        font-size: 24px;
        color: #f1f1f6; }
    .ao-app .ao-app__header-container .ao-breadcrumb {
      margin-left: 1rem; }
  .ao-app .ao-app__navigation-container {
    height: 100%;
    width: auto; }
  .ao-app .ao-app__content {
    margin-left: 64px;
    padding: 0;
    display: flex;
    flex: 1 1;
    flex-direction: column;
    overflow-y: auto; }
    .ao-app .ao-app__content .ao-app__route-container {
      flex: 1;
      width: 100%;
      position: relative; }
  .ao-app .ao-app__footer {
    text-align: center;
    margin-top: auto;
    height: 40px; }
  .ao-app .ao-route-enter-active {
    animation: fadeIn 300ms both;
    animation-delay: 100ms;
    z-index: 2; }
  .ao-app .ao-route-exit-active {
    animation: fadeOut 200ms both;
    z-index: 1; }
